export enum LogLevelStr {
   Trace = 'Trace',
   Debug = 'Debug',
   Information = 'Information',
   Warning = 'Warning',
   Error = 'Error',
   Critical = 'Critical',
   None = 'None'
}

export enum LogLevel {
   Trace = 0,
   Debug = 1,
   Information = 2,
   Warning = 3,
   Error = 4,
   Critical = 5,
   None = 6
}

export enum Role {
   SuperAdmin = 'SuperAdmin',
   Admin = 'Admin',
   PartnerAdmin = 'PartnerAdmin',
   OrgAdmin = 'OrgAdmin',
   User = 'User',
   Anonymous = 'Anonymous'
}

export enum FileSystemNodeClassifier {
   Image = 'Image',
   Audio = 'Audio',
   Video = 'Video',
   Document = 'Document',
   View = 'View',
   External = 'External',
   Avatar = 'Avatar',
   Model = 'Model',
   RichText = 'RichText',
   Blob = 'Blob',
   SerializedBoard = 'SerializedBoard',
   RoomImage = 'RoomImage',
   RoomVideo = 'RoomVideo',
   ThemeImage = 'ThemeImage',
   UserImage = 'UserImage',
   BoardImage = 'BoardImage',
   Font = 'Font',
   BoardLoadDiagnosticData = 'BoardLoadDiagnosticData',
   RoomDiagnosticData = 'RoomDiagnosticData',
   AppDiagnosticData = 'AppDiagnosticData'
}

export enum FileSystemNodeType {
   File = 'File',
   Folder = 'Folder'
}

export enum FileSystemContainerPermission {
   Viewer = 'Viewer',
   Editor = 'Editor',
   Owner = 'Owner'
}

export enum FileContainerScope {
   Global = 'Global',
   Partner = 'Partner',
   Organization = 'Organization',
   User = 'User',
   Board = 'Board',
   ThirdParty = 'ThirdParty'
}

export enum FileScope {
   None = 'None',
   Global = 'Global',
   Organization = 'Organization',
   User = 'User',
   Board = 'Board',
   BoardLibrary = 'BoardLibrary'
}

export enum TranslationSourceType {
   Global = 'Global',
   App = 'App',
   Admin = 'Admin',
   Backend = 'Backend'
}

export enum ConfigurationTarget {
   Global = 'Global',
   Partner = 'Partner',
   Organization = 'Organization'
}

export enum ConfigurationSection {
   ThirdParty = 'ThirdParty',
   Board = 'Board',
   App = 'App',
   ClientSecret = 'ClientSecret'
}

export enum ConfigurationSectionBoard {
   DrawingColors = 'DrawingColors',
   StickyNoteColors = 'StickyNoteColors',
   TextColors = 'TextColors',
   AdaptiveCardHostConfig = 'AdaptiveCardHostConfig',
   MapMarkerColors = 'MapMarkerColors',
   Fonts = 'Fonts'
}

export enum ConfigurationSectionThirdParty {
   MapTiler = 'MapTiler',
   Unsplash = 'Unsplash',
   Giphy = 'Giphy',
   GoogleMaps = 'GoogleMaps'
}

export enum ConfigurationSectionClientSecret {
   MetaCX = 'MetaCX'
}

export enum CapabilityTarget {
   Global = 'Global',
   Partner = 'Partner',
   Organization = 'Organization',
   UserPlan = 'UserPlan',
   User = 'User',
   Board = 'Board'
}

export enum CapabilityType {
   FileSystemNodeClassifier = 'FileSystemNodeClassifier',
   Storage = 'Storage',
   Users = 'Users',
   Boards = 'Boards',
   AppFeatures = 'AppFeatures',
   BoardFeatures = 'BoardFeatures',
   BoardReadOnlyFeatures = 'BoardReadOnlyFeatures',
   BoardMeetingRoomFeatures = 'BoardMeetingRoomFeatures'
}

export enum InviteType {
   UserInviteStatic = 'UserInviteStatic',
   OrganizationInviteStatic = 'OrganizationInviteStatic'
}

export enum ThirdPartyAuthProvider {
   Slack = 'Slack',
   Microsoft = 'Microsoft',
   Google = 'Google',
   Zoom = 'Zoom',
   Lifesize = 'Lifesize',
   Webex = 'Webex',
   BlueJeans = 'BlueJeans',
   Chime = 'Chime',
   ArcGis = 'ArcGis'
}

export enum ThirdPartyConnectMode {
   Default = 'Default',
   HapticConnect = 'HapticConnect',
   HapticLogin = 'HapticLogin',
   HapticSignUp = 'HapticSignUp',
   External = 'External'
}

export enum ThirdPartyConnectionScope {
   Default = 'Default',
   Profile = 'Profile',
   ZoomApp = 'ZoomApp',
   SlackApp = 'SlackApp',
   GoogleDrive = 'GoogleDrive',
   MicrosoftOneDrive = 'MicrosoftOneDrive',
   MicrosoftMeeting = 'MicrosoftMeeting',
   GoogleMeeting = 'GoogleMeeting',
   ZoomMeeting = 'ZoomMeeting',
   MicrosoftCalendar = 'MicrosoftCalendar',
   GoogleCalendar = 'GoogleCalendar',
   ArcGisApp = 'ArcGisApp'
}

export enum ThirdPartyConnectionType {
   Default = 'Default',
   Auth = 'Auth',
   AuthAndDataAccess = 'AuthAndDataAccess',
   App = 'App'
}

export enum ThirdPartyScopeType {
   Default = 'Default',
   Channel = 'Channel',
   Calendar = 'Calendar'
}

export enum BoardAccessLevel {
   Private = 'Private',
   Organization = 'Organization',
   Public = 'Public'
}

export enum BoardClassification {
   Infinite = 'Infinite',
   Map = 'Map',
   FixedSlide = 'FixedSlide',
   InfiniteFixedSlide = 'InfiniteFixedSlide',
   Webpage = 'Webpage'

   // System types
   // FixedSlideChild = 'FixedSlideChild'
}

export enum BoardType {
   Default = 'Default',
   Template = 'Template',
   Orphan = 'Orphan',
   RoomWhiteboard = 'RoomWhiteboard'
}
