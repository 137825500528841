export enum FeatureToggle {
   // Global
   SupportChat = 'SupportChat',
   LanguageSelect = 'LanguageSelect',
   ForgotPassword = 'ForgotPassword',

   SlackLogin = 'SlackLogin',
   ZoomLogin = 'ZoomLogin',
   GoogleLogin = 'GoogleLogin',
   MicrosoftLogin = 'MicrosoftLogin',
   HapticLogin = 'HapticLogin',

   SlackRegister = 'SlackRegister',
   ZoomRegister = 'ZoomRegister',
   GoogleRegister = 'GoogleRegister',
   MicrosoftRegister = 'MicrosoftRegister',
   HapticRegister = 'HapticRegister',

   SlackConnect = 'SlackConnect',
   ZoomConnect = 'ZoomConnect',
   GoogleConnect = 'GoogleConnect',
   MicrosoftConnect = 'MicrosoftConnect',

   ZoomApp = 'ZoomApp',
   SlackApp = 'SlackApp',

   GoogleFiles = 'GoogleFiles',
   MicrosoftFiles = 'MicrosoftFiles',

   MicrosoftMeetings = 'MicrosoftMeetings',
   GoogleMeetings = 'GoogleMeetings',
   ZoomMeetings = 'ZoomMeetings',
   LifesizeMeetings = 'LifesizeMeetings',
   WebexMeetings = 'WebexMeetings',
   BlueJeansMeetings = 'BlueJeansMeetings',
   ChimeMeetings = 'ChimeMeetings',

   WebinarHapticMeeting = 'WebinarHapticMeeting',

   MicrosoftCalendar = 'MicrosoftCalendar',

   VonageWebRtc = 'VonageWebRtc',

   // Dashboard
   CoverImageSearch = 'CoverImageSearch',
   MapBoardType = 'MapBoardType',
   Calendar = 'Calendar',

   // Board
   DrawingMenu = 'DrawingMenu',
   ToolsMenu = 'ToolsMenu',
   MoreToolsMenu = 'MoreToolsMenu',
   ColorMenu = 'ColorMenu',
   ExperimentalMenu = 'ExperimentalMenu',

   BoardTopBar = 'BoardTopBar',

   UserPanel = 'UserPanel',
   ManageGuests = 'ManageGuests',

   AssetPanel = 'AssetPanel',
   AssetDownload = 'AssetDownload',
   AssetFolderDownload = 'AssetFolderDownload',
   AssetUpload = 'AssetUpload',
   AssetMetaEdit = 'AssetMetaEdit',
   AssetAppStreamEdit = 'AssetAppStreamEdit',

   ViewsPanel = 'ViewsPanel',

   ObjectActions = 'ObjectActions',

   CursorMode = 'CursorMode',
   MarkerMode = 'MarkerMode',
   MarkerDrawingMode = 'MarkerDrawingMode',
   EraserMode = 'EraserMode',

   BoardSettingsDialog = 'BoardSettingsDialog',
   BoardHotkeys = 'BoardHotkeys',

   TextObject = 'TextObject',
   StickyNoteObject = 'StickyNoteObject',
   ScreenShareObject = 'ScreenShareObject',
   LiveVideoObject = 'LiveVideoObject',
   LiveVideoActiveSpeaker = 'LiveVideoActiveSpeaker',
   StickerObject = 'StickerObject',
   StickyPadObject = 'StickyPadObject',
   VncObject = 'VncObject',
   AppStreamFirefoxObject = 'AppStreamFirefoxObject',
   RichTextObject = 'RichTextObject',
   EmbedObject = 'EmbedObject',
   ContainerObject = 'ContainerObject',
   SlideObject = 'SlideObject',
   ModelObject = 'ModelObject',
   CommentObject = 'CommentObject',
   ClockObject = 'ClockObject',
   AdaptiveCardObject = 'AdaptiveCardObject',
   ExternalWidgetObject = 'ExternalWidgetObject',
   CollaborativeNotepadObject = 'CollaborativeNotepadObject',

   Spotlight = 'Spotlight',
   UserCursorTimeout = 'UserCursorTimeout',
   UserMouseTrails = 'UserMouseTrails',
   UserUnmuteVoicePrompt = 'UserUnmuteVoicePrompt',

   GlobalSearch = 'GlobalSearch',
   HapticSearch = 'HapticSearch',
   AdaptiveCardDesigner = 'AdaptiveCardDesigner',

   Chat = 'Chat',
   VoiceChat = 'VoiceChat',
   PdfExport = 'PdfExport',

   // TODO: This is now a "Temporary" user upgrade (rename)
   AnonymousSignInOrUp = 'AnonymousSignInOrUp',

   ContributorPermission = 'ContributorPermission',

   ClearBoard = 'ClearBoard',
   ExitBoard = 'ExitBoard',
   BoardQrCode = 'BoardQrCode',

   BoardMessageChatsClear = 'BoardMessageChatsClear',
   BoardMessageCommentsClear = 'BoardMessageCommentsClear',

   SupportDialog = 'SupportDialog',
   RecordDialog = 'RecordDialog',

   SlideAddContextMenu = 'SlideAddContextMenu',
   SlidePresentContextMenu = 'SlidePresentContextMenu',
   SlideSettingsContextMenu = 'SlideSettingsContextMenu',
   DocumentCloudEditContextMenu = 'DocumentCloudEditContextMenu',
   DocumentCloudUpdateContextMenu = 'DocumentCloudUpdateContextMenu',
   TextColorsContextMenu = 'TextColorsContextMenu',
   TextFontsSubMenu = 'TextFontsSubMenu',
   StickyNoteFontsContextMenu = 'StickyNoteFontsContextMenu',
   StickyNoteBackgroundColorContextMenu = 'StickyNoteBackgroundColorContextMenu',
   StickyNoteOutlineColorContextMenu = 'StickyNoteOutlineColorContextMenu',
   StickyNoteShapeContextMenu = 'StickyNoteShapeContextMenu',
   PathStrokeColorContextMenu = 'PathStrokeColorContextMenu',
   MoreContextMenu = 'MoreContextMenu',
   CommentsContextMenu = 'CommentsContextMenu',

   ObjectActionOnDownloadFile = 'ObjectActionOnDownloadFile',
   // ObjectActionDownloadFile = 'ObjectActionDownloadFile',

   // Room board
   RoomCursorMode = 'RoomCursorMode',
   RoomMarkerMode = 'RoomMarkerMode',
   RoomColorMenu = 'RoomColorMenu',
   RoomMarkerDrawingMode = 'RoomMarkerDrawingMode',
   RoomEraserMode = 'RoomEraserMode',
   RoomCanvasSettings = 'RoomCanvasSettings',
   RoomSave = 'RoomSave',
   RoomCanvasColor = 'RoomCanvasColor',
   RoomCanvasGrid = 'RoomCanvasGrid',

   // Read only board
   ReadOnlyVoice = 'ReadOnlyVoice',
   ReadOnlyExit = 'ReadOnlyExit'

   // LegacyTheme = 'LegacyTheme'
}
